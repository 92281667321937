<template>
  <div class="ecosistema">
    <BNavbar></BNavbar>

    <div class="bg-ecosistema py-5">
      <b-container class="py-5">
        <b-col xs="12" class="p-0 text-center">
          <div style="height: 220px">
            <h1
              v-if="visibleTitle"
              class="display-4 text-primary font-weight-light pt-3"
            >
              <typewriter class="typewriter mt-5" :type-interval="30">
                <span> Nuestro ecosistema </span>
              </typewriter>
            </h1>
            <transition name="fade">
              <h4 v-if="visibleSubTitle" class="mt-4 font-weight-light">
                Así agrupamos hoy nuestras distintas empresas
              </h4>
            </transition>
          </div>
          <transition name="fade">
            <div
              class="btn-toolbar"
              role="toolbar"
              aria-label="Toolbar with button groups "
              v-if="!Mobile && visibleButtons"
            >
              <div
                class="btn-group btn-group-ecosistema mt-3 mx-auto"
                role="group"
                aria-label="First group"
              >
                <button
                  type="button"
                  class="btn btn-ecosistema"
                  @click="scrollInto('hcm')"
                >
                  HCM
                </button>
                <button
                  type="button"
                  class="btn ml-1 btn-ecosistema"
                  @click="scrollInto('erp')"
                >
                  ERP
                </button>
                <button
                  type="button"
                  class="btn ml-1 btn-ecosistema"
                  @click="scrollInto('innova')"
                >
                  INNOVA
                </button>
                <button
                  type="button"
                  class="btn ml-1 btn-ecosistema"
                  @click="scrollInto('ecosistema')"
                >
                  ECOSISTEMA
                </button>
                <button
                  type="button"
                  class="btn ml-1 btn-ecosistema"
                  @click="scrollInto('exits')"
                >
                  EXITS
                </button>
              </div>
            </div>
            <div
              class="btn-toolbar mt-5"
              role="toolbar"
              aria-label="Toolbar with button groups"
              v-else-if="visibleButtons"
            >
              <div
                class="btn-group-vertical btn-group-ecosistema mt-5 mx-auto"
                role="group"
                aria-label="First group"
              >
                <button
                  type="button"
                  class="btn btn-ecosistema"
                  @click="scrollInto('hcm')"
                >
                  HCM
                </button>
                <button
                  type="button"
                  class="btn mt-1 btn-ecosistema"
                  @click="scrollInto('erp')"
                >
                  ERP
                </button>
                <button
                  type="button"
                  class="btn mt-1 btn-ecosistema"
                  @click="scrollInto('innova')"
                >
                  INNOVA
                </button>
                <button
                  type="button"
                  class="btn mt-1 btn-ecosistema"
                  @click="scrollInto('ecosistema')"
                >
                  ECOSISTEMA
                </button>
                <button
                  type="button"
                  class="btn mt-1 btn-ecosistema"
                  @click="scrollInto('exits')"
                >
                  EXITS
                </button>
              </div>
            </div>
          </transition>
        </b-col>
      </b-container>
    </div>

    <transition name="fade">
      <div v-if="visibleBody">
        <div class="bg-white pb-5">
          <!-- HCM -->
          <b-col class="pt-5 bg-hcm" id="hcm">
            <b-container>
              <b-row class="pt-5">
                <b-col class="pt-4">
                  <div
                    class="h1 display-3 text-white mt-5 pt-lg-5 font-weight-light"
                  >
                    HCM
                  </div>
                  <div
                    class="p text-white display-5 font-weight-bold text-uppercase ls-sable"
                  >
                    Human Capital Management
                  </div>
                  <div
                    class="p text-white font-weight-medium p-0 mt-3 paragraph-ecosiystem-section"
                  >
                    Bajo este alero agrupamos nuestras empresas que buscan
                    apoyar
                    <br />
                    y optimizar la gestión de los colaboradores de nuestros
                    clientes.
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-container>
            <b-row class="row mt-3">
              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://rexmas.com/" target="_blank">
                  <div
                    class="card p-4 margin-negative-lg-card margin-negative-md-card margin-negative-sm-card"
                  >
                    <img src="/assets/hcm-1.webp" width="22%" alt="Logo Rex+" />
                    <div class="p font-weight-bold text-uppercase mt-4">
                      Rex+
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Plataforma de remuneraciones y gestión de personas
                    </div>
                  </div>
                </a>
              </b-col>

              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://mandomedio.com/" target="_blank">
                  <div
                    class="card p-4 margin-negative-lg-card margin-negative-md-card"
                  >
                    <img
                      src="/assets/hcm-2.webp"
                      width="40%"
                      alt="Logo Mandomedio"
                    />
                    <div class="p font-weight-bold text-uppercase mt-4">
                      Mandomedio
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Proveedor de múltiples servicios de gestión de personas
                    </div>
                  </div>
                </a>
              </b-col>

              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://www.vixonic.com/" target="_blank">
                  <div class="card p-4 margin-negative-lg-card">
                    <img
                      src="/assets/hcm-3.webp"
                      width="40%"
                      alt="Logo Vixonic"
                    />
                    <div class="p font-weight-bold text-uppercase mt-4">
                      Vixonic
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Plataforma de comunicación <br />corporativa
                    </div>
                  </div>
                </a>
              </b-col>
              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://www.bnovus.cl/" target="_blank">
                  <div class="card p-4">
                    <img
                      src="/assets/hcm-4.webp"
                      width="40%"
                      alt="Logo Bnovus"
                    />
                    <div class="p font-weight-bold text-uppercase mt-4">
                      Bnovus
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Plataforma de gestión de asistencia y desarrollo
                      organizacional
                    </div>
                  </div>
                </a>
              </b-col>

              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://insigni.cl/" target="_blank">
                  <div class="card p-4">
                    <img
                      src="/assets/hcm-5.webp"
                      width="40%"
                      alt="Logo Insigni"
                    />
                    <div class="p font-weight-bold text-uppercase mt-4">
                      Insigni
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Asesoría de alta gerencia y dirección corporativa
                    </div>
                  </div>
                </a>
              </b-col>

              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="http://www.gaxu.cl/ " target="_blank">
                  <div class="card p-4">
                    <img src="/assets/hcm-6.webp" width="27%" alt="Logo Gaxu" />
                    <div class="p font-weight-bold text-uppercase mt-4 pt-2">
                      Gaxu
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Gestión de asistencia, accesos y otras habilitaciones
                      digitales
                    </div>
                  </div>
                </a>
              </b-col>
            </b-row>
          </b-container>

          <!-- ERP -->

          <b-col class="py-5 mt-5 bg-erp" id="erp">
            <b-container>
              <b-row class="pt-3 pt-lg-5">
                <b-col class="pt-2 pt-lg-4">
                  <div
                    class="h1 display-3 text-white mt-5 pt-5 font-weight-light"
                  >
                    ERP
                  </div>
                  <div
                    class="p text-white display-5 font-weight-bold text-uppercase ls-sable"
                  >
                    Enterprise Resource Planing
                  </div>
                  <div
                    class="p text-white font-weight-medium mt-3 paragraph-ecosiystem-section"
                  >
                    Bajo esta línea de negocio se encuentran nuestras empresas
                    <br />
                    que buscan hacer más eficientes tus procesos de producción,
                    <br />
                    negocio y compliance, adaptándose a las necesidades de cada
                    empresa.
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-container>
            <b-row class="mt-3">
              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://manager.cl/ " target="_blank">
                  <div
                    class="card p-4 margin-negative-lg-card margin-negative-md-card margin-negative-sm-card"
                  >
                    <img
                      src="/assets/erp-1.webp"
                      width="40%"
                      alt="Logo Manager"
                    />
                    <div class="p font-weight-bold text-uppercase mt-3">
                      Manager
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      ERP financiero contable con múltiples módulos de gestión
                    </div>
                  </div>
                </a>
              </b-col>

              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://www.random.cl/" target="_blank">
                  <div
                    class="card p-4 margin-negative-lg-card margin-negative-md-card"
                  >
                    <img
                      src="/assets/erp-2.webp"
                      width="40%"
                      alt="Logo Random"
                    />
                    <div class="p font-weight-bold text-uppercase mt-3">
                      Random
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Producción, manufactura, logística, distribución y venta
                      retail
                    </div>
                  </div>
                </a>
              </b-col>

              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a
                  href="https://manager.cl/soluciones/flexline-erp/ "
                  target="_blank"
                >
                  <div class="card p-4 margin-negative-lg-card">
                    <img
                      src="/assets/erp-3.webp"
                      width="40%"
                      alt="Logo Flexline"
                    />
                    <div class="p font-weight-bold text-uppercase mt-3">
                      Flexline
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      ERP financiero contable basado en ciclos documentarios
                    </div>
                  </div>
                </a>
              </b-col>
              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://ordename.cl/" target="_blank">
                  <div class="card p-4">
                    <img
                      src="/assets/erp-4.webp"
                      width="40%"
                      alt="Logo Ordename"
                    />
                    <div class="p font-weight-bold text-uppercase mt-3">
                      Ordename
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Aplicación de gestión de pedidos y boletas para almacenes
                    </div>
                  </div>
                </a>
              </b-col>
            </b-row>
          </b-container>
          <!-- Innova -->
          <b-col class="py-5 mt-5 bg-innova" id="innova">
            <b-container>
              <b-row class="pt-lg-5">
                <b-col class="pt-4">
                  <div
                    class="h1 display-3 text-white pt-5 mt-5 font-weight-light"
                  >
                    Innova
                  </div>
                  <div
                    class="p display-5 text-white font-weight-bold text-uppercase ls-sable"
                  >
                    Habilitación Digital
                  </div>
                  <div class="p text-white mt-3 paragraph-ecosiystem-section">
                    Bajo este alero agrupamos nuestras empresas que buscan hacer
                    <br />
                    más eficientes tus procesos de producción, compliance y
                    negocios
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
          <b-container>
            <b-row class="mt-3">
              <b-col xs="12" md="6" lg="4" class="s mb-4">
                <a href="https://labs.jumpitt.com/" target="_blank">
                  <div
                    class="card p-4 margin-negative-lg-card margin-negative-md-card margin-negative-sm-card"
                  >
                    <img
                      src="/assets/innova-1.webp"
                      width="40%"
                      alt="Logo Jumpitt"
                    />
                    <div class="p font-weight-bold text-uppercase mt-3">
                      Jumpitt
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Software factory y laboratorio <br />de innovación
                    </div>
                  </div>
                </a>
              </b-col>

              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://www.trust.lat/" target="_blank">
                  <div
                    class="card p-4 margin-negative-lg-card margin-negative-md-card"
                  >
                    <img
                      src="/assets/innova-2.webp"
                      width="30%"
                      alt="Logo Trust"
                    />
                    <div class="p font-weight-bold text-uppercase mt-3">
                      Trust
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Plataforma de gestión de aplicaciones y dispositivos
                      móviles
                    </div>
                  </div>
                </a>
              </b-col>

              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a
                  href="https://www.linkedin.com/company/sable-latam/"
                  target="_blank"
                >
                  <div class="card p-4 margin-negative-lg-card">
                    <img
                      src="/assets/innova-3.webp"
                      width="30%"
                      class="mb-3 pt-2"
                      alt="Logo Sable"
                    />
                    <div class="p font-weight-bold text-uppercase mt-3">
                      Sable Tech
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Nuestro alero de inversión e incubación de nuevos negocios
                    </div>
                  </div>
                </a>
              </b-col>
              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://i-car.cl/ " target="_blank">
                  <div class="mt-lg-3 card p-4">
                    <img
                      src="/assets/innova-5.webp"
                      class="mb-3 pt-2"
                      width="25%"
                      alt="Logo i-Car"
                    />
                    <div class="p font-weight-bold text-uppercase mt-3">
                      i-Car
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Plataforma de procesos digitales para la industria
                      automotriz
                    </div>
                  </div>
                </a>
              </b-col>
              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://www.notarisa.cl/" target="_blank">
                  <div class="card p-4 mt-lg-3">
                    <img
                      src="/assets/innova-4.webp"
                      width="40%"
                      alt="Logo Notarisa"
                    />
                    <div class="p font-weight-bold text-uppercase mt-3">
                      Notarisa
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Plataforma de procesos digitales para apoyar la gestión
                      notaria
                    </div>
                  </div>
                </a>
              </b-col>
              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://cl.senegocia.com/" target="_blank">
                  <div class="card p-4 mt-lg-3">
                    <img
                      src="/assets/erp-5.webp"
                      width="35%"
                      alt="Logo Senegocia"
                    />
                    <div class="p font-weight-bold text-uppercase mt-3">
                      Senegocia
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Plataforma de compras y abastecimientos
                    </div>
                  </div>
                </a>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <div class="bg-light buss-section pb-5 px-5">
          <b-container>
            <b-col md="3" class="border-blue" id="ecosistema"></b-col>

            <b-col class="p-0 mt-5 ecosistema">
              <div class="h1 font-weight-light">
                Empresas asociadas al ecosistema
              </div>
              <div class="p font-weight-regular col-md-6 p-0 mt-3">
                En este segmento están las empresas en las que invertimos y
                colaboramos fuertemente en desarrollar
              </div>
            </b-col>

            <b-row class="mt-5">
              <b-col sm="12" md="6" lg="4" class="mb-4">
                <div class="card p-4">
                  <a href="https://grupoinmotion.com/" target="_blank">
                    <img
                      src="/assets/buss-1.webp"
                      width="50%"
                      alt="Logo Inmotion"
                    />
                  </a>
                  <div class="p font-weight-bold text-uppercase mt-3">
                    Inmotion
                  </div>
                  <div class="p font-weight-regular p-0 mt-3">
                    Partner digital estratégico con soluciones para seguros,
                    telecomunicaciones, banca, retail y gobierno
                  </div>
                </div>
              </b-col>

              <b-col sm="12" md="6" lg="4" class="mb-4">
                <div class="card p-4">
                  <a href="https://happ.cl/" target="_blank">
                    <img
                      src="/assets/buss-2.webp"
                      width="30%"
                      alt="Logo Happ"
                    />
                  </a>
                  <div class="p font-weight-bold text-uppercase mt-4">Happ</div>
                  <div class="p font-weight-regular p-0 mt-3">
                    Solución de salud digital que administra testeos y
                    procedimientos de salud en el lugar de trabajo
                  </div>
                </div>
              </b-col>

              <b-col sm="12" md="6" lg="4" class="mb-4">
                <div class="card p-4">
                  <a href="https://riivi.com/" target="_blank">
                    <img
                      src="/assets/buss-3.webp"
                      width="20%"
                      alt="Logo Riivi"
                    />
                  </a>
                  <div class="p font-weight-bold text-uppercase mt-4">
                    Riivi
                  </div>
                  <div class="p font-weight-regular p-0 mt-3">
                    Streaming gratuito de contenido exclusivamente
                    latinoamericano
                  </div>
                </div>
              </b-col>

              <b-col sm="12" md="6" lg="4" class="mb-4">
                <div class="card p-4">
                  <a
                    href="https://www.linkedin.com/company/bewellsalud/"
                    target="_blank"
                  >
                    <img
                      src="/assets/buss-4.webp"
                      width="20%"
                      alt="Logo Bewell"
                    />
                  </a>

                  <div class="p font-weight-bold text-uppercase mt-4">
                    Bewell
                  </div>
                  <div class="p font-weight-regular p-0 mt-3">
                    Insurtech de corretaje de seguros que simplifica trámites a
                    usuarios
                  </div>
                </div>
              </b-col>

              <b-col sm="12" md="6" lg="4" class="mb-4">
                <div class="card py-3 px-4">
                  <a href="https://regcheq.com/" target="_blank">
                    <img
                      src="/assets/buss-5.webp"
                      class="pb-1 pt-2"
                      width="35%"
                      alt="Logo Primero Cotiza"
                    />
                  </a>

                  <div class="p font-weight-bold text-uppercase mt-4">
                    regcheq
                  </div>
                  <div class="p font-weight-regular p-0 mt-3">
                    Software de Compliance para la gestión de obligaciones regulatorias de forma digital y automatizada
                  </div>
                </div>
              </b-col>
              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://www.fingo.cl/" target="_blank">
                  <div class="card p-4">
                    <img
                      src="/assets/erp-6.webp"
                      width="27%"
                      alt="Logo Fingo"
                      class="pt-2"
                    />
                    <div class="p font-weight-bold text-uppercase mt-4 pt-2">
                      Fingo
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Plataforma de Financiamiento
                    </div>
                  </div>
                </a>
              </b-col>
              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://cincel.digital/" target="_blank">
                  <div class="card p-4 mt-lg-3">
                    <img
                      src="/assets/innova-6.webp"
                      width="30%"
                      alt="Logo Cincel"
                    />
                    <div class="p font-weight-bold text-uppercase mt-4 pt-2">
                      Cincel
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Software de firma digital con blockchain para Pymes de
                      LATAM
                    </div>
                  </div>
                </a>
              </b-col>
              <b-col xs="12" md="6" lg="4" class="mb-4">
                <a href="https://transatuauto.cl/" target="_blank">
                  <div class="card p-4 mt-lg-3">
                    <img
                      src="/assets/innova-7.webp"
                      width="20%"
                      alt="Logo Transa tu auto"
                    />
                    <div class="p font-weight-bold text-uppercase mt-4 pt-2">
                      Transa tu auto
                    </div>
                    <div class="p font-weight-regular p-0 mt-3">
                      Plataforma de transferencia vehicular digital
                    </div>
                  </div>
                </a>
              </b-col>
            </b-row>
          </b-container>
        </div>

        <div class="bg-white p-5" id="exits">
          <b-container>
            <b-col class="p-0 mt-5">
              <div class="h1 font-weight-light">Exits</div>
              <div class="p font-weight-regular col-md-6 p-0 mt-3">
                Las creamos y/o las desarrollamos,<br />pero hoy no son parte de
                nuestro grupo
              </div>
            </b-col>

            <b-col md="10" class="p-0 col-md-10">
              <div class="exits">
                <div class="pb-4">
                  <div class="p font-weight-bold small text-uppercase ls-sable">
                    orden
                  </div>
                  <div class="p font-weight-regular col-md-6 p-0 mt-1 small">
                    Creada en 1980, uno de los primeros integradores
                    tecnológicos latinoamericanos. Fue adquirida por SONDA en
                    2004
                  </div>
                </div>

                <div class="py-4">
                  <div class="p font-weight-bold small text-uppercase ls-sable">
                    dicom
                  </div>
                  <div class="p font-weight-regular col-md-6 p-0 mt-1 small">
                    Incorporada en 1987, plataforma que revolucionó la gestión
                    crediticia en Chile. Fue adquirida por EQUIFAX en 1997
                  </div>
                </div>

                <div class="py-4">
                  <div class="p font-weight-bold small text-uppercase ls-sable">
                    payroll
                  </div>
                  <div class="p font-weight-regular col-md-6 p-0 mt-1 small">
                    Incorporada a Sable en 1995, plataforma de remuneraciones
                    líder en Latinoamérica. Fue adquirida por ADP en 2013
                  </div>
                </div>

                <div class="py-4">
                  <div class="p font-weight-bold small text-uppercase ls-sable">
                    imed
                  </div>
                  <div class="p font-weight-regular col-md-6 p-0 mt-1 small">
                    Creada en 2002, ecosistema de gestión de trámites de salud
                    líder en Chile. Fue adquirida por ACELL-KKR en 2017
                  </div>
                </div>

                <div class="py-4">
                  <div class="p font-weight-bold small text-uppercase ls-sable">
                    acepta
                  </div>
                  <div class="p font-weight-regular col-md-6 p-0 mt-1 small">
                    Incorporada a Sable en 2012. Plataforma de documentos
                    electrónicos y procesos biométricos con presencia
                    latinoamericana. Fue adquirida por SOVOS en 2020.
                  </div>
                </div>
              </div>
            </b-col>
          </b-container>
        </div>

        <div class="ecosistema-separator py-5">
          <b-container class="py-5">
            <b-col sm="12" class="p-0">
              <div class="h1 text-white font-weight-light">
                Conversemos el futuro ahora.
              </div>
              <router-link
                v-on:click="scrollToTop"
                to="/contacto"
                class="btn btn-light text-primary mt-4"
                >Contáctanos</router-link
              >
            </b-col>
          </b-container>
        </div>

        <Footer></Footer>
      </div>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import BNavbar from "@/components/BNavbar.vue";
import Footer from "@/components/Footer.vue";
import Typewriter from "typewriter-vue";
import { isMobile } from "mobile-device-detect";

export default {
  name: "Ecosistema",
  components: {
    BNavbar,
    Footer,
    Typewriter,
  },

  data() {
    return {
      scrollPosition: null,
      visibleTitle: false,
      visibleSubTitle: false,
      visibleButtons: false,
      visibleBody: false,
      targetHCM: document.querySelector(".hcm"),
      Mobile: isMobile ? true : false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    // var targetERP = document.querySelector(".erp");
    // var targetINNOVA = document.querySelector(".innova");
    // var targetECOSISTEMA = document.querySelector(".ecosistema");
    // var targetEXITS = document.querySelector(".exits");
    setTimeout(() => (this.visibleTitle = true), 1000);
    setTimeout(() => (this.visibleSubTitle = true), 2000);
    setTimeout(() => (this.visibleButtons = true), 2500);
    setTimeout(() => (this.visibleBody = true), 2800);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    scrollTo(yPos) {
      window.scroll(0, yPos);
    },
    scrollInto(elementId) {
      const section = document.querySelector(`#${elementId}`);
      section.scrollIntoView({
        behavior: "smooth",
      });
    },
  },
  metaInfo() {
    return {
      title: `Nuestro Ecosistema | Grupo Sable`,
      meta: [
        {
          name: "description",
          content:
            "Nuestro Ecosistema de Soluciones Digitales compuesto por tres verticarles de negocios: HCM (Human Capital Management), ERP y Habilitación Digital.",
        },
      ],
      link: [{ rel: "canonical", href: "https://sable.lat/ecosistema" }],
    };
  },
};
</script>
